* {
	// box-sizing: border-box;
	margin: 0;
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
    text-rendering: geometricPrecision;
}

ul * {
	list-style: none;
}

a {
	text-decoration: none;
}