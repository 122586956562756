@import '../../../../../styles/base.scss';
@import '../../../../../styles/reset.scss';

.main__inputs {
    display: flex;
    flex-direction: column;
    // margin: 0 50px;

    .forms__wrapper {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 10px;
        margin: 0 50px;
        
        &:nth-child(2),
        &:nth-child(4) {
            // border-bottom: 1px solid $brandBackground;
        }
        &:nth-child(3) {
           // padding-top: 40px;
        }

        .forms__field {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            width: 48%;
            height: 60px;
            font-size: 16px;

            .field__input {
                font-size: 14px;
                line-height: 18px;
                padding: 10px 0;
                z-index: 1000;
                padding: 4px 0;
                border-bottom: 1px solid $brandBorderColor;
            }

            .field__label {
                position: absolute;
                top: 20px;
                font-size: 14px;
                line-height: 18px;
                color: $brandPantonText;
                transition: all 0.3s ease-out;

                &.active {
                    position: absolute;
                    top: 0px;
                    font-size: 12px;
                    color: $brandPantonSubText;
                }
            }
        }
    }
    
    .forms__unsuccess {
        display: block;
        position: absolute;
        top: 45px;
        text-align: center;
        color: $brandPantonRed;
    }

    .forms__success {
        color: $brandPantonGreen;
    }

    .forms__notifications {
        display: flex;
        flex-direction: column;
        margin: 40px 50px 30px 50px;

        .notifications__wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-bottom: 10px;

            input[type="checkbox"] {
                width: 16px;
                height: 16px;
                margin-right: 10px;
            }
        }
    }

    .forms__button {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 20px 0px;
        background-color: $brandBackground;

        .button {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 196px;
            // height: 30px;
            padding: 13px 20px;                
            border-radius: 30px;
            background-color: $brandPantonBlue;
            color: $white;
            font-family: $font-regular;
            border: 1px solid transparent;

            &:hover {
                // width: 196px;
                // height: 30px;
                // border-radius: 30px;
                // background-color: $white;
                // color: $brandPantonBlue;
                // font-family: $font-regular;
                // border: 1px solid $brandPantonBlue;
                cursor: pointer;
            }
        }
    }
}