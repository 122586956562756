@import '../../../../../styles/reset.scss';
@import '../../../../../styles/base.scss';

.wrap__read {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 360px);
    height: 100vh;
    background: rgba(0, 0, 0, 0.473);
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
    z-index: 10000;
}
.read__news {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    z-index: 1000;
    background-color: $white;

    .read__header {
        display: flex;
        justify-content: flex-end;
        padding: 30px;

        .image {
            width: 20px;
            height: 20px;
            background: url('../../../../../images/news/close_read.svg') no-repeat center;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .read__photo {
        display: flex;
        flex: 1;
        padding: 0 30px;
        height: 200px;

        .photo {
            width: 100%;
            height: 100%;
            background:  url('../../../../../images/news/news.png') no-repeat center;
            background-size: cover;
        }
    }

    .read__article {
        display: flex;
        flex-direction: column;
        flex: 2;
        // background-color: $white;
        z-index: 1000;

        .article__header {
            display: flex;
            margin-top: 30px;
            margin-bottom: 20px;
            margin-left: 30px;
            font-size: 18px;
            color: $brandPantonBlue;
        }
        
        .article__text {
            display: flex;
            height: 30vh;
            overflow-y: scroll;
            margin: 0 30px 30px 30px;
            line-height: 26px;
        }
    }
}

.invisible {
    .wrap__read {
        width: calc(100vw - 100px);
    }
}