@import '../../../../../styles/base.scss';
@import '../../../../../styles/reset.scss';

.company__news {
    display: flex;
    width: 100%;
    height: 100%;

    .news__info {
        display: flex;
        flex-direction: column;
        width: 100%;

        div {
            display: flex;
            align-items: center;
            position: relative;
            height: 100%;
            overflow: hidden;
            border-bottom: 1px solid $brandPantonFonts;
        }

        .info__title {
            display: flex;
            flex: 1;
            align-items: center;
            position: absolute;
            width: 1000px;
            // padding: 12px 0;
            margin-bottom: 0px;
            color: $brandPantonBlack;
            // white-space: nowrap;
            // text-overflow: ellipsis;
            // overflow: hidden;

            &:hover {
                cursor: pointer;
            }
            
            .title__icon {
                margin: 20px;
            }

            .title__date {
                color: $brandPantonBlue;
                margin-right: 19px;
            }

            // .title__info {
               // white-space: nowrap;
            // }
        }
    }
}