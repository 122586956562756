@import '../../../../styles/reset.scss';
@import '../../../../styles/base.scss';

.alert__notifications {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    width: 460px;
    background-color: $white;
    z-index: 1000;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);

    .alert__header {
        text-align: center;
        padding: 15px 0;
        font-size: 16px;
        color: $brandPantonBlack;

        & > span {
            margin-left: 12px;
            color: $brandPantonBlue;
        }
    }

    li {
        display: flex;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid $brandBackground;

        & > .list__icon {
            width: 20px;
            height: 20px;
            margin-left: 20px;
            margin-right: 15px;
        }

        & > .text {
            display: flex;
            flex-direction: column;

            & > span:nth-child(1) {
                margin-bottom: 5px;
                color: $brandPantonBlack;
                font-size: 14px;
                
                &:hover {
                    color: $brandPantonBlue;
                }
            }

            & > span:nth-child(2) {
                color: $brandPantonSubText;
                font-size: 12px;
            }
        }
    }

    .alert__more {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 0;
        font-size: 12px;
        color: $brandPantonSubText;

        .more__arrow {
            display: block;
            width: 10px;
            height: 5px;
            margin-top: 1px;
            margin-left: 10px;
            background: url('../../../../images/arrow_alert.svg') no-repeat center;
        }

        &:hover {
            cursor: pointer;
        }
    }
}