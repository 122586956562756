@import './base.scss';
@import './reset.scss';

input.switch {
    position: relative;
    -webkit-appearance: none;
    outline: none;
    width: 45px;
    height: 25px;
    background-color: $white;
    border: 1px solid $brandBorderColor;
    border-radius: 50px;
    box-shadow: inset -20px 0 0 0 $white;
}

input.switch:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    background: transparent;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}

input.switch:checked {
    box-shadow: inset 20px 0 0 0 $brandPantonBlue;
    border-color: $brandPantonBlue;
}

input.switch:checked:after {
    left: 20px;
    box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
    // background-color: $brandBorderColor;
}

input.switch:hover {
    border-bottom: 1px solid $brandBorderColor !important;
}

input.switch:focus {
    border-bottom: 1px solid $brandPantonBlue !important
}

/** switch red */
input.switch--red {
    position: relative;
    -webkit-appearance: none;
    outline: none;
    width: 45px;
    height: 25px;
    background-color: $white;
    border: 1px solid $brandBorderColor;
    border-radius: 50px;
    box-shadow: inset -20px 0 0 0 $white;
}

input.switch--red:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    background: transparent;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}

input.switch--red:checked {
    box-shadow: inset 20px 0 0 0 $brandPantonRed;
    border-color: $brandPantonRed;
}

input.switch--red:checked:after {
    left: 20px;
    box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}

input.switch--red:hover {
    border-bottom: 1px solid $brandBorderColor !important;
}

input.switch--red:focus {
    border-bottom: 1px solid $brandPantonRed !important
}