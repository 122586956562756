@import "../../../styles/base.scss";

/**
 * ********** Стили компонента TopNav **********
 */
.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    background-color: $white;
    border-bottom: 2px solid $brandPantonFonts;
    position: fixed;
    width: 100%;
    z-index: 10000;

    .nav__left {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .left__hamburger {
            margin-left: 38px;
            font-size: 30px;
            color: $brandPantonBlue;

            &:hover {
                cursor: pointer;
            }
        }

        .left__logo {
            display: block;
            width: 238px;
            height: 30px;
            margin-left: 27px;
            color: $white;
            background: url('../../../images/carddex_logo.svg') no-repeat center;
            background-size: contain;
        }
    }

    .nav__right {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: $brandPantonBlack;
        padding-left: 80px;

        .right__text {
            font-size: 14px;
            line-height: 17px;
            color: $brandPantonBlue;

            .text__name {
                margin-left: 10px;
                font-size: 16px;
                font-weight: 300;
                color: $brandPantonGrey;
            }
        }

        .right__info {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

            .info__bell {
                display: block;
                position: relative;
                padding: 10px 40px 10px 0px;
                border-right: 2px solid $brandPantonFonts;

                .bell__icon {
                    display: block;
                    position: relative;
                    width: 30px;
                    height: 30px;
                    background: url("../../../images/bell.svg") no-repeat center;
                    background-size: contain;
                    cursor: pointer;

                    .notifications__number {
                        display: block;
                        position: absolute;
                        top: 1px;
                        right: 0;
                        padding: 1px 4px;
                        border-radius: 50%;
                        font-size: 9px;
                        color: $white;
                        background-color: $brandPantonBlue;
                    }

                }
            }

            .info-list {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0 30px 0 15px;

                &__text {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-right: 15px;
                    text-align: center;

                    .name {
                        margin-bottom: 7px;
                        font-weight: $light;
                        font-size: 16px;
                        line-height: 15px;
                        color: $brandPantonText;
                    }

                    .role {
                        font-weight: $light;
                        font-size: 14px;
                        line-height: 13px;
                        color: $brandPantonSubText;
                    }
                }
            }

            .alert {
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 50px;
                right: -42px;
                width: 600px;
                background-color: $white;
                z-index: 1000;
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);

                .alert__header {
                    text-align: center;
                    padding: 15px 0;
                    font-size: 16px;
                    color: $brandPantonBlack;

                    & > span {
                        margin-left: 12px;
                        color: $brandPantonBlue;
                    }
                }

                li {
                    display: flex;
                    padding: 10px;
                    border-bottom: 1px solid $brandBackground;

                    & > .list__icon {
                        width: 20px;
                        height: 20px;
                        margin-left: 20px;
                        margin-right: 15px;
                    }

                    & > span {
                        color: $brandPantonBlack;
                        font-size: 14px;
                        
                        &:hover {
                            color: $brandPantonBlue;
                        }
                    }
                }

                .alert__more {
                    text-align: center;
                    padding: 15px 0;
                    font-size: 12px;
                    color: $brandPantonSubText;

                    &::after {
                        //content: '\02C6';
                        //width: 10px;
                        //height: 10px;
                        margin-left: 5px;
                        //font-size: 35px;
                    }
                }
            }
        }
    }
}

.hamburger-visible {
    display: block !important;
}

@media all and (max-width: 1366px) {
    .right-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .top-nav__info {
        font-size: 17px;
    }

    .right-top {
        .email {
            font-size: 14px;
        }

        .role {
            font-size: 14px;
        }
    }
}

@media all and (max-width: 1172px) {
    .right-top {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    .left__logo {
        display: none!important;
        width: 0;
    }

    .top-right__block {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        min-width: 488px;
    }

    .top-nav__info {
        font-size: 17px;
        justify-content: flex-end;
        margin-right: 2em;
    }

    .right-top {
        .email {
            font-size: 14px;
        }

        .role {
            font-size: 14px;
        }
    }
}