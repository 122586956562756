@import "../../styles/base.scss";

.wrapper {
    display: flex;
    position: relative;
    width: 100vw;
    height: 100vh;
    justify-content: space-between;
    // font-family: $code;
}

// .right {
//     background-color: $brandPantonFonts;
//     height: 100vh;
// }

/* width */
::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar:horizontal {
    height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    width: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $brandPantonSubText;
    border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $brandPantonSubText;
}

.main-container {
    display: flex;
    margin-top: 70px;
    background: $brandBackground;
}

.wrapper__chat {
    display: flex;
}