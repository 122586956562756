@import '../../../../../../styles/reset.scss';
@import '../../../../../../styles/base.scss';

.wrap__read {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 360px);
    height: 100vh;
    background: rgba(0, 0, 0, 0.473);
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
    z-index: 10000;
}
.read__offer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    z-index: 1000;
    background-color: $white;

    .read__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $white;
        padding: 30px 20px 5px 60px;

        .left {
            display: flex;
            align-items: center;

            .left__text {
                margin-right: 17px;
                font-weight: $bold;
                font-size: 14px;
            }
        }

        .image {
            width: 20px;
            height: 20px;
            background: url('../../../../../../images/news/close_read.svg') no-repeat center;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .read__photo {
        display: flex;
        height: 200px;
        padding: 0 60px;
        background-color: $white;

        .photo {
            width: 100%;
            height: 100%;
            background: url('../../../../../../images/news/news.png') no-repeat center;
            background-size: contain;
        }
    }

    .read__article {
        display: flex;
        flex-direction: column;
        // flex: 2;
        background-color: $white;
        z-index: 1000;

        .article__header {
            display: flex;
            margin: 30px 60px 20px 60px;
            font-size: 18px;
            color: $brandPantonBlue;
        }
        
        .article__text {
            display: flex;
            height: 10vh;
            margin: 0 60px;
            line-height: 26px;
            // border: 1px solid red;
        }
    }

    .read__date {
        display: flex;
        margin: 30px 60px;
        // background-color: $white;

        .date__calendar {
            margin-right: 10px;
        }
    }

    .read__attention {
        display: flex;
        margin: 0 60px 30px 60px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);

        .attention__left {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin: 20px;

            .left__header {
                display: flex;
                margin-bottom: 20px;
                color: $brandPantonBlack;

                .icon {
                    margin-right: 10px;
                }
            }

            .left__text {
                display: flex;

                .text {
                    max-width: 300px;
                    margin-left: 30px;
                    color: $brandPantonBlack;
                }
            }
        }

        .attention__right {
            display: flex;

            .photo {
                width: 90px;
                height: 90px;
                margin: 20px;
                border-radius: 8px;
                background-color: $brandPantonGreyText;
            }
        }
    }

    .read__button {
        display: flex;
        padding: 0 60px 40px 60px;

        .text {
            padding: 15px 0px;
            font-size: 14px;
            color: $brandPantonBlue;
        }

        .button__offer {
            padding: 15px 38px;
            border-radius: 30px;
            background-color: $brandPantonBlue;
            color: $white;
            font-family: $font-regular;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.invisible {
    .wrap__read {
        width: calc(100vw - 100px);
    }
}