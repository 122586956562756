@import "../../styles/base.scss";

/**
 * ********** Стили компонента Авторизация **********
 */
 .wrapper {
	display: flex;
	flex-direction: column;
	font-family: $font-regular;
}

/* Input autofill */
input:-webkit-autofill {
	box-shadow: 0 0 0 1000px $white inset;
}

input {
	&:focus, &:hover {
		border-bottom: 1px solid $brandPantonBlue !important;
		transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
	}
}

.auth {
	display: flex;
	flex-direction: row;
	height: 100vh;
	width: 100%;
	font-weight: $light;
	font-family: $font-regular;
	font-size: 14px;
	line-height: 18px;
	color: $brandPantonBlack;

	&__left {
		display: flex;
		flex-direction: column;
		width: 50%;
		padding: 150px 220px;
		background-color: $white;
		overflow: auto;
		z-index: 1000;
	}

	&__heading {
		// margin-bottom: 30px;

		h1 {
			font-size: 26px;
			line-height: 33px;
			font-weight: 400;
		}
	}

	&__necessary {
		margin-top: 10px;
		font-size: 12px;
		color: $brandPantonSubText;
	}

	&__forms {
		flex: 2 100%;
		display: flex;
		flex-direction: column;
		position: relative;
	}

	&__group {
		position: relative;
		margin: 10px 0;

		.auth__field {
			height: 60px;
			display: flex;
			flex-direction: column-reverse;
			position: relative;
		}

		.auth__input {
			font-size: 14px;
			line-height: 18px;
			padding: 10px 0;
			border-bottom: 1px solid $brandBorderColor;
		}

		.auth__label {
			position: absolute;
			top: 30px;
			font-size: 14px;
			line-height: 18px;
			color: $brandPantonText;
			transition: all 0.3s ease-out;

			&.active {
				position: relative;
				top: 10px;
				font-size: 12px;
				color: $brandPantonSubText;
			}
		}
	}

	&__password-ask {
		display: flex;
		margin-top: 30px;
		margin-bottom: 10px;

		label {
			margin-right: 20px;

			input {
				width: 20px;
				height: 20px;
			}
		}

		.ask__password {
			margin-top: 10px;
			font-size: 12px;
			line-height: 18px;
			color: $brandPantonBlue;
			text-decoration: underline;
		}

		span {
			font-size: 12px;
			color: $brandPantonSubText;

			&:hover {
				cursor: pointer;
			}
		}

		a {
			margin-top: 30px;
			text-decoration: underline;
			color: $brandPantonBlue
		}
	}

	&__button {
		display: flex;

		.button {
			width: 196px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 30px 0;
			background: $brandPantonBlue;
			font-family: $font-regular;
			font-size: 14px;
			color: $white;
			border: none;
			border-radius: 15px;
			cursor: pointer;
		}
	}

	&__registration {
		font-size: 12px;
		line-height: 18px;
		color: $brandPantonSubText;

		span:first-child {
			color: $brandPantonBlue;
			text-decoration: underline;

			&:hover {
				cursor: pointer;
			}
		}
	}

	&__error {
		padding: 10px 0px 30px 0px;
	}

	&__back {
		display: flex;
		position: absolute;
		bottom: 0;
		font-size: 12px;
		color: $brandPantonBlue;
		border-bottom: 1px solid $brandPantonBlue;

		&::before {
			content: '\2190';
			padding-right: 5px;
		}
	}

	&__logo {
		width: 224px;
		display: flex;
		flex-direction: column;
	}

	&__text {
		margin: 35px 0;	
		font-size: 14px;
		line-height: 18px;

		a {
			margin: 0 10px;
			color: $brandPantonBlue;
		}
	}

	&__right {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 50%;
		height: 100%;
		margin-left: 4px;
		background: $brandPantonFonts;
		//opacity: .9;

		&__image,
		.image {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;
			width: 100%;
			height: 100%;
			background: radial-gradient(50% 50% at 50% 50%, rgba(167, 167, 167, 0) 0%, #B8B8B8 100%);

			
			img {
				display: block;
				position: absolute;
				top: 16vh;
			}

			&__photo {
				display: block;
				width: 100%;
				height: 100%;
				background-image: url('../../images/Auth/auth.png');
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}
}

/**
 * ********** Стили для выбранной формы Логин/Регистрация, стили доступности кнопки **********
 */

.warning {
	border-bottom: 1px solid $brandPantonRed !important;
}

.unsuccess {
	color: $brandPantonRed
}

.success {
	color: $brandPantonGreen;
}

.remove__scrollbar {
	&::-webkit-scrollbar {
		width: 0;
	}
}

.inaccess {
	display: block;
	position: absolute;
	right: 0;
	width: 20px;
	height: 20px;
	margin-bottom: 10px;
	background: url('../../images/eye-inaccess.svg') no-repeat center;
	z-index: 1000;
	cursor: pointer;
}

.access {
	background: url('../../images/eye-access.svg') no-repeat center;
	z-index: 1001;
}

.inaccessible {
	background-color: $brandPantonFonts!important;
	color: $brandPantonSubText!important;
}

/**
 * ********** Стили для адаптива компонента Авторизация **********
 */

@media screen and (max-width:1440px) {
	.auth {
		&__left {
			padding: 120px;
		}

		&__right {
			&__logo {
				top: 124px;
			}

		}
	}
	img {
		top: 14vh!important;
	}
}

@media screen and (max-width:1024px) {
	.auth {
		&__left {
			padding: 60px;
		}

		&__right {
			&__logo {
				top: 64px;
			}
		}
	}

	img {
		top: 7vh!important;
	}
}

@media screen and (max-width:850px) {
	.auth {
		width: 100%;

		&__text {
			font-size: 12px;
		}

		&__left {
			width: 100%;
			padding: 30px;
		}

		&__right {
			display: none;
		}

		&__button {
			display: flex;
			justify-content: center;
			margin: 30px;
		}
	}
}