@import '../../../../../styles/base.scss';

.wrap__catalog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.heading__text {
    width: 100%;
    margin-bottom: 30px;
}

.catalog__basket {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: $brandPantonBlue;
    color: $white;
}

.catalog {
    display: flex;
    flex-direction: column;
    width: 1020px;

    .catalog__main {
        display: flex;
    }

    .main {
        flex: 2;
        margin-bottom: 20px;
    }

    .catalog__pagination {
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding: 0 30px;
        background-color: $white;
    }
}