@import "../../../../styles/base.scss";


.main-content {
    height: calc(100vh - 70px - 20px - 20px);
    flex: 2 100%;
    flex-direction: column;
    position: relative;
    top: calc(70px + 20px);
    margin: 0 17px 0 20px;
    transition: all 0.5s ease 0s;
    font-family: "Gotham Pro Regular";
    font-size: 14px;
    color: $brandPantonGrey;
    overflow: auto;
}

/** ********** MAIN STYLES **********/
.main {
    flex-direction: column;
    // padding: 0 17px 0 20px;

    &__block {
        margin-bottom: 20px;

        &:last-child {
            margin: 0;
        }

        &.news {
            .block-container {
                width: 100%;
                grid-template-columns: repeat(2, 1fr);
                // grid-template-rows: repeat(2, 1fr);
                // grid-row: auto / span 2;
                grid-gap: 20px;
            }

            .block-element {
                padding: 20px;
            }
            //.block-element:nth-child(1) {
                // grid-column: auto / span 3;
            //}
            // .block-element:nth-child(1){
            //     margin-bottom: 10px;
            //     margin-right: 15px;
            //     padding: 0;
            // }
            // .block-element:nth-child(3) {
            //     padding: 0;
            //     margin-right: 15px;
            // }
            .block-element:nth-child(2) {
                // grid-row: auto / span 2;
                // margin-left: 15px;
                padding: 0;
            }
        }

        // &.data {
        //     .block-container {
        //         width: 100%;
        //         grid-template-columns: repeat(2, 1fr);
        //     }
        //     // .block-element:nth-child(1) {
        //     //     grid-column: auto / span 3;
        //     // }
        //     // .block-element:nth-child(2) {
        //     //     grid-column: auto / span 2;
        //     // }
        // }

        &.status {
            .block-container {
                grid-template-columns: repeat(2, 1fr);
            }
            .block-element:nth-child(1) {
                .block-element__button {
                    display: flex;
                }
            }
            .block-element:nth-child(1) {
                .block-element__info {
                    flex: none;
                    display: flex;
                    flex-direction: column;
                    border-right: 1px solid $brandBorderColor;
                    margin-right: 30px;
                    padding-right: 30px;
                }
            }

            // .block-element:nth-child(4) {

            // }
            // .block-element:nth-child(1) {
            //     grid-column: auto / span 3;
            // }
            // .block-element:nth-child(2) {
            //     grid-column: auto / span 2;
            // }

            // .block-table {
            //     grid-column: auto / span 3;
            // }

            // .discount {
            //     grid-column: auto / span 2;
            // }

            .block-element:nth-child(2) {
                .block-element__title {
                    .title__link {
                        color: $brandPantonBlue;
                    }
                }
                .block-element__subtitle {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        &.seggestions {
            .block-element {
                max-width: 600px;
                padding: 0px;

                &__title {
                    margin-left: 20px;
                    padding: 20px 0 10px 0;
                    font-size: 14px;
                    line-height: 18px;
                    color: $brandPantonSubText;

                    svg {
                        width: 14px;
                        height: 14px;
                        fill: $brandPantonSubText;
                        margin-right: 7px;
                    }
                }

                &__subtitle {
                    margin-bottom: 10px;
                    margin-left: 20px;
                    font-size: 16px;
                    line-height: 23px;
                }

                &__description {
                    margin-bottom: 20px;
                    margin-left: 20px;
                    font-size: 14px;
                    line-height: 18px;
                    color: $brandPantonSubText;
                }

                &__info {
                    // width: 100%;
                    // max-width: 500px;
                    margin-right: 0;
                    padding-right: 0;
                }
            }
        }
    }


    .block-title {
        width: 100%;
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 40px;

        &__important {
            height: 60px;
            display: flex;
            align-items: center;
            font-size: 18px;
            line-height: 25px;
            color: $brandPantonBlack;
            background: $white;
            // box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);

            span {
                display: flex;
                align-items: center;
                // background-color: $white;
                // padding: 18px 0;

                &::before {
                    content: "\0069";
                    min-width: 33px;
                    height: 33px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 18px 15px 18px 20px;
                    font-family: serif;
                    font-size: 25px;
                    border: 2px solid $brandPantonBlue;
                    border-radius: 50%;
                }
            }
        }
    }

    .block-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row: auto / span 15;
        grid-gap: 20px;
    }

    .more__offers {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
        font-size: 14px;
        color: $brandPantonSubText;

        &::after {
            content: "\003E";
            min-width: 33px;
            padding-top: 3px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-left: 10px;
            font-family: serif;
            font-size: 17px;
        }
    }

    .block-table {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);

        &__row {
            display: flex;
            // font-size: 16px;
            line-height: 15px;
            background: $brandBackground;

            &:nth-child(2n) {
                background: $white;
            }

            &.header {
                line-height: 17px;
                background: $white;
                color: $brandPantonBlue;
                border-bottom: 1px solid $brandBackground;

                .block-table__element {
                    border: none;
                }
                & > div > span {
                    font-size: 16px;
                }
            }

            .block-table__element {
                &:nth-child(1) {
                    flex: 1 35%;
                    justify-content: flex-end;
                }

                &:nth-child(2) {
                    flex: 1 15%;
                    justify-content: flex-end;
                }

                &:nth-child(3) {
                    flex: 1 50%;
                }

                & > span {
                    font-size: 14px;
                }
            }
        }

        &__element {
            display: flex;
            align-items: center;
            flex: 1 100%;
            height: 50px;
            padding: 0 30px;

            &:nth-child(2n) {
                border-left: 1px solid $brandBackground;
                border-right: 1px solid $brandBackground;
            }

            .sale-conditions {
                display: flex;
                
                .performed {
                    width: 14px;
                    height: auto;
                    margin-left: 10px;
                }
            }
        }
    }

    .stock {
        display: flex;
        flex-direction: column;

        .heading {
            padding-bottom: 20px;
            font-size: 14px;
            color: $brandPantonBlue;
        }

        .stock__top {
            width: 100%;
            padding-top: 7px;
            padding-bottom: 26px;
            font-size: 16px;
            color: $brandPantonBlue;
        }

        .stock__center {
            display: flex;
            width: 100%;

            .center__left {
                display: flex;
                flex-direction: column;
                flex: 2;
                padding: 20px;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);

                .left__chart {
                    display: block;
                    margin-bottom: 16px;
                    height: 8px;
                    background-color: $brandPantonGreen;
                }

                .left__range {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    .range__left {
                        font-size: 16px;
                        font-weight: 700;
                        color: $brandPantonGreen;
                    }

                    .range__right {
                        font-size: 16px;
                        font-weight: 700;
                        color: $brandPantonGreyText;
                    }
                }
            }

            .center__right {
                display: flex;
                flex-direction: column;
                flex: 1;
                margin-left: 15px;
                padding: 20px;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);

                .right__calendar {
                    display: flex;
                    flex-direction: row;
                    margin-top: 10px;

                    .calendar__icon {
                        margin-right: 10px;
                    }
                }
            }
        }

        .stock__bottom {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 20px;
            margin-top: 14px;
            background-color: $brandBackground;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);

            .bottom__summ {
                font-size: 14px;
                color: $brandPantonGreyText;
            }
        }
    }

    .block-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .block-element {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 20px;
        background: $white;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);

        &__chart {
            flex: 1 100%;
            max-width: 141px;
            width: 100%;
            position: relative;
            margin-right: 30px;
            border-radius: 50%;
        }

        &__icon {
            min-width: 90px;
            width: 90px;
            height: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 30px;
            background: $brandBackground;

            svg {
                width: 46px;
                height: 46px;
                fill: $brandPantonSubText;
            }
        }

        &__info {
            flex: 2;
            display: flex;
            flex-direction: column;

            .offer__image {
                margin: 0px 20px;
            }
        }
    

        &__submit {
            display: flex;
            margin: 20px 0;

            .button {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 13px 20px;
                color: $brandPantonBlue;
                border: 1px solid $brandPantonBlue;
                border-radius: 30px;

                .icon > svg {
                    fill: $brandPantonBlue;
                    stroke: none;
                    margin-left: 20px;
                }

                &:hover {
                    border: 1px solid $brandPantonBlue;
                    background-color: $brandPantonBlue;
                    color: $white;
                    cursor: pointer;

                    .icon > svg {
                        stroke-width: 1px;
                        fill: $white;
                    }
                }
            }
        }

        &__title {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 15px;
            color: $brandPantonBlue;
            word-break: break-word;

            .offer {
                margin-right: 15px;
                padding: 7px;
                color: $white;
                background-color: $brandPantonBlue;
            }

            .text {
                margin-right: 10px;
                font-size: 14px;
                word-wrap: nowrap;
                font-weight: $bold;
                color: $brandPantonBlue;
            }

            .date {
                display: flex;
                flex: 1;
            }

            .icon {
                margin-right: 17px;
            }
        }

        &__subtitle {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            line-height: 18px;
        }
        &__subtitle--offer {
            margin-left: 18px;
            padding: 20px 0;
            color: $brandPantonBlue;
            font-size: 16px;
            line-height: 18px;
        }

        &__link {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 5px;

            .link__more {
                max-width: 0;
                display: flex;
                align-items: center;
                overflow: hidden;
                color: #1d68d9;
                transition: all .2s ease-in-out;
            }

            &:hover {

                .link__more {
                    display: flex;
                    max-width: 100px;
                    margin-right: 20px;
                    color: $brandPantonBlue;
                }
            }
        }

        &__button {
            display: flex;
            align-items: center;
            font-size: 15px;
            line-height: 14px;
            color: $brandPantonBlue;
            border-radius: 30px;
            cursor: pointer;
            transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);

            svg {
                fill: none;
                stroke: $brandPantonBlue;
            }
        }
        &__stock {
            display: flex;
            border: 1px solid $brandPantonGreen;
        }
    }
}

.currency-unit-rub {
    &::after {
        content: '\20BD';
        margin-left: 5px;
        font-family: Arial, Helvetica, sans-serif;
    }
}

.CircularProgressbar .CircularProgressbar-trail {
    stroke: $brandPantonFonts !important;
    stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: $brandPantonGreen !important;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-text {
    fill: $brandPantonBlack !important;
    font-size: 20px;
    text-anchor: middle;
}

@media (max-width: 1400px) {
    .status .block-container:nth-child(2n) {
        grid-column: 1 / span 2;
    }
}

@media (max-width: 1549px) {
    .main {
        .status {
            .block-container {
                grid-template-columns: repeat(3, 1fr);

                & > *:nth-child(2n-1) {
                    grid-column: 1 / span 2;
                }
            }
        } 
        .seggestions {
            .block-container {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        .block-container {
            grid-gap: 20px;
        }
    }

    .news {
        .block-container {

            grid-template-columns: repeat(3, 1fr)!important;

            & > *:nth-child(2n - 1) {
                grid-column: 1 / span 2;
            }
        }
    }
}

@media (max-width: 1370px) {
    .block-element__info {
        flex-direction: column;
        border: none!important;
    }
    .block-element {
        justify-content: center!important;
    }
}

