@import "../../../../styles/base.scss";

/**
 * ********** Стили компонента Конфигуратор **********
 */

.configurator {
    display: flex;
    background: $white;
    padding: 20px;

    &__iframe {
        width: 100%;
        height: 90vh;
    }
}