@import "../../../../styles/base.scss";

/**
 * ********** Стили компонента Прайс-лист **********
 */
.pricelist {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: $white;

    .pricelist__heading {
        display: flex;
        flex-direction: column;
        position: relative;

        &>*:nth-child(n) {
            margin-bottom: 10px;
        }

        .pricelist__modal {
            display: none; // it turn off
            flex-direction: column;
            position: absolute;
            top: 90px;
            right: 10px;
            width: 257px;
            height: 180px;
            border: 1px solid $brandPantonFonts;
            background-color: $white;
            z-index: 1000;

            .pricelist__block--left {
                display: flex;
                justify-content: flex-end;
            }

            .modal__block {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px;

                .block__text {
                    margin-left: 10px;
                }

                .block__button {
                    padding: 10px 30px;
                    background-color: $white;
                    border-radius: 40px;
                    color: $brandPantonBlue;
                    border: 1px solid $brandPantonBlue;

                    &:hover {
                        background-color: $brandPantonBlue;
                        color: $white;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .pricelist__table {
        overflow-x: scroll;

        .pricelist__frame {
            display: flex;
            flex-direction: column;

            .frame__caption {
                display: flex;
                flex-direction: column;
                position: sticky;
                top: 0;
                width: 180px; //min-width: 150px;
                background-color: $white;

                .wrap__index {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;
                    text-align: center;
                    height: 40px;
                    padding: 5px;
                    background: $white;
                    border-left: 1px solid $brandPantonFonts;
                    border-top: 1px solid $brandPantonFonts;
                    border-bottom: 1px solid $brandPantonFonts;
                    cursor: pointer;
                    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);

                    &--active {
                        color: $white!important;
                        background: $brandPantonBlue;


                        /* стили для изменения иконки при фильтрации */
                        .index__icon--sorting {
                            display: block;
                            margin-left: 10px;
                            width: 23px;
                            height: 14px;
                            background: url('../../../../images/sorting_column_white.svg')!important;
                        }

                        /* стили для изменения иконки при фильтрации */
                        .index__icon--filtering {
                            display: block;
                            width: 16px;
                            height: 14px;
                            margin-left: 10px;
                            background: url('../../../../images/filter_white.svg')!important;
                        }
                    }

                    .index__text {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        color: $brandPantonBlue;
                    }

                    .index__icon--sorting {
                        display: block;
                        margin-left: 10px;
                        width: 23px;
                        height: 14px;
                        background: url('../../../../images/sorting_column.svg') no-repeat center;
                    }

                    .index__icon--filtering {
                        display: block;
                        width: 16px;
                        height: 14px;
                        margin-left: 10px;
                        background: url('../../../../images/filter.svg') no-repeat center;
                        
                    }

                    &:hover {
                        border: 1px solid $brandPantonBlue;
                    }

                }
            }

            .frame__table {
                display: flex;
                flex-direction: row;

                .table__column {
                    // flex: 1;
                    display: flex;
                    flex-direction: column;

                    // &:nth-child(1) {
                    //     flex: 2;
                    // }

                    // &:nth-child(2) {
                    //     flex: 4;
                    // }

                    
                    &:nth-child(3) {
                        .column__item {
                            justify-content: flex-end;
                        }
                    }

                    &:nth-child(4) {
                        .column__item {
                            min-width: 200px;
                            justify-content: flex-end;
                        }
                    }

                    &:last-child {
                        border-right: 1px solid $brandBorderColor;
                    }

                    .column__item {
                        display: flex;
                        align-items: center;
                        height: 30px;
                        padding: 5px;
                        // max-width: 425px;
                        width: 180px; //min-width: 150px;
                        background-color: $white;
                        border-left: 1px solid $brandBorderColor;
                        border-bottom: 1px solid $brandBorderColor;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        cursor: default;

                        span {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 50ch;
                        }

                        &:nth-child(odd) {
                            background-color: #f1f1f1;
                        }

                        .item__text {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                        }

                        .item__icon {
                            display: block;
                            width: 16px;
                            height: 16px;
                            margin-right: 5px;
                            background: url('../../../../images/plus_item.svg') no-repeat center;
                            background-size: contain;

                            &:hover {
                                cursor: pointer;
                            }
                        }

                        &:hover {
                            border: 1px solid $brandPantonBlue;
                        }
                    }
                }
            }
        }
    }
}

.heading__text {
    color: $brandPantonBlack;
    font-size: 20px;
    font-family: $font-regular;
}

.heading__buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    width: 100%;

    &>*:nth-child(n) {
        margin: 10px 0;
    }

    .buttons-wrapper {
        flex: 1;
        display: flex;
    }

    &>.buttons,
    &>*>.buttons {
        display: block;
        padding: 13px 20px;
        margin-right: 14px;
        white-space: nowrap;
        border: 1px solid $brandPantonBlue;
        color: $brandPantonBlue;
        border-radius: 40px;
        font-family: $font-regular;
        cursor: pointer;
        transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);

        &:hover,
        &--active {
            background-color: $brandPantonBlue!important;
            color: $white!important;
            cursor: pointer;
        }
    }
}

.search-wrapper {
    display: flex;
    // padding: 10px 0;
    height: 40px;
    justify-content: center;
    border-bottom: 2px solid $brandPantonFonts;
    background-color: $brandBackground; 
    //border-left: 1px solid $brandPantonFonts;
    //border-bottom: 1px solid  $brandPantonFonts;
    //border-color: #ebeff3;

    &:nth-child(2) {
        .column__item {
            width: 200px;
        }
    }

    &:nth-child(3) {
        .column__item {
            width: 200px;
        }
    }

    &:focus-within {
        // border: 1px solid $brandPantonBlue;
    }

    .search-input {
        flex: 1;
        width: 100%;
        padding: 0 10px;
        background-color: $brandBackground;    

        &:hover,
        &:focus {
            border: none !important;
        }
    }

    .search__image {
        &:hover {
            cursor: pointer;
        }
    }

    .search-icon {
        width: 20px;
        margin-right: 10px;
        cursor: pointer;
    }
}