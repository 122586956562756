
@import "./reset.scss";
/**
 * ********** Переменные основных цветов **********
 */
$white: #fff;
$brandBackground: #FAFAFA;
$brandSideColor: #404040;
$brandPantonBlack: #191E22;
$brandPantonRed: #ff0000;
$brandPantonGreen: #20CD32;
$brandPantonFonts: #ebeff3;
$brandPantonGrey: #191E22;
$brandPantonBlue: #1d68d9;
$brandPantonText: #293440;
$brandPantonSubText: #8b9298;
$brandPantonGreyText: #C4C4C4;
$brandSideBorderColor: #94cbfd;
$brandBorderColor: #EFEFEF;

$margin: 20px;

/**
 * ********** Начертания шрифтов **********
 */
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$heavy: 800;

/**
 * ********** Шрифты **********
 */
$code: 'Gotham Pro Light';
/** Gotham Pro */
$font-light: 'Gotham Pro Light';
$font-regular: 'Gotham Pro Regular';