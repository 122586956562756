@import "../../../styles/base.scss";

.side {
    flex: 1 400px;
    width: 100%;
    min-width: 360px;
    height: calc(100vh - 70px);
    overflow: auto;
    border-right: 2px solid $brandPantonFonts;
    background: $white;
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    &__top {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
        font-family: $font-regular;
        transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
        user-select: none;

        .nav-link {
            display: block;
            margin: 20px 20px 0 20px;            
            border: 1px solid $brandPantonFonts;
            border-radius: 10px;
            cursor: pointer;

            .font {
                // border: 1px solid $brandPantonSubText;
                border-radius: 10px;
                color: $brandBackground;
            }

            &:last-child {
                margin-bottom: 20px;
            }

            & > *:first-child {
                border-radius: 10px;
            }

            & > *:last-child {
                & > *:last-child li {
                    border-radius: 0 0 10px 10px;
                }
            }

            & > *:only-child {
                border-radius: 10px;
            }
            
            .font, .subparagraph {
                height: 60px;
                display: flex;
                align-items: center;
                padding: 0 20px;
                transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);

                &:hover {
                    background: $brandBackground;
                }

                .list-name {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    position: relative;
                    margin-left: 20px;
                    font-size: 14px;
                    color: $brandPantonBlack;
                    text-overflow: clip;
                    white-space: nowrap;
                    overflow: hidden;
                    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);

                    &__sales {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        color: $brandPantonBlue;
    
                        &::before {
                            content: "";
                            width: 5px;
                            height: 5px;
                            display: block;
                            margin-right: 3px;
                            border-radius: 50%;
                            background: $brandPantonBlue;
                        }
                    }

                    &__icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 30px;
                        height: 30px;
                        position: absolute;
                        right: 0;
                        transition: all 0.15s cubic-bezier(0.02, 0.01, 0.47, 1);
                        background: url('../../../images/arrow_drop.svg') no-repeat center;
                        background-size: contain;
                    }

                    &__icon.turn {
                        transform: rotate(-180deg);
                    }
                }

                .font-block-hover {
                    width: 0;
                    visibility: hidden;

                    .hover__block {
                        text-overflow: clip;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }
            }

            .icon {
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: 20px;
                    height: 20px;
                    fill: $brandPantonText;
                    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
                }
            }
        }

        // .nav--active {
        //     box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
        // }

        .active-page {

            .font, .subparagraph {
                background: $brandPantonBlue !important;

                .icon {
                    svg {
                        fill: $white !important;
                    }
                }

                .list-name {
                    color: $white !important;
                }

                .list-name__sales {
                    color: $white;

                    &::before {
                        background: $white;
                    }
                }
            }

            .hover__block {
                background: $brandPantonBlue !important;
            }
            .hover-block--icon {
                svg {
                    fill: $white!important;
                }
            }
            
        }

        .submenu {
            display: none;
            border-top: 1px solid $brandBackground;

            &.show {
                display: block;
            }

            .sub-link {
                display: block;
            }
            
            .subparagraph {
                position: relative;
                padding-left: 40px;

                .list-name__icon {
                    display: none;
                }
            }
        }

        & a:last-child {
            .font {
                svg {
                    fill: $brandPantonBlue;
                }
            }
        }
    }
}

.invisible .side {
    flex: 1 100px;
    min-width: 100px;

    .nav-link {
        // border: none;
        .font-block-hover {
            position: absolute;
            visibility: hidden;
            left: 0;
            transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);

            .hover__block {
                display: none;                
                transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
            }

            .active-page {
                background: $brandPantonBlue !important;

                .hover__block--icon {
                    svg {
                        fill: $white !important;
                    }
                }

                .hover__block--text {
                    color: $white !important;
                }
            }
        }
        &:hover {
            border: 1px solid transparent;
            .font-block-hover {
                display: flex;
                flex-direction: column;
                left: 100px;
                width: 230px;
                height: 60px;
                visibility: visible;
                border: 1px solid $brandBackground;
                border-radius: 0 10px 10px 0;
                background-color: $white;
                z-index: 1000;

                .hover__block {
                    display: flex;
                    align-items: center;
                    padding: 30px 0;
                    color: $brandPantonBlack;
                    // border-radius: 0 10px 10px 0;
                    background-color: $white;

                    .hover__block--icon {
                        width: 18px;
                        // height: 18px;
                        margin: 0 20px;
                        transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
                    }

                    .hover__block--text {
                        color: $brandPantonBlack;
                        font-size: 14px;
                    }

                    &:hover {
                        background-color: $brandBorderColor;
                    }
                }
            }
        }
    }

    .font {
        padding: 20px;
        // border-radius: 10px;
        // border: 1px solid $brandPantonFonts;
        // transition: all 10.25s cubic-bezier(0.02, 0.01, 0.47, 1);

        &:hover {
            border-radius: 10px 0 0 10px;
            width: 79px;
        }

        .list-name {
            width: 0%;
            margin: 0;
        }
    }

    .submenu {
        display: none;
    }
}