//@import "../styles/base.scss";

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 70px);
  position: sticky;
  top: 70px;
}

.indicator {
  width: 50px;
}
