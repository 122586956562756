@import '../../../../styles/reset.scss';
@import '../../../../styles/base.scss'; 

.main-content {
    height: calc(100vh - 70px - 20px - 20px);
    flex: 2 100%;
    flex-direction: column;
    position: sticky;
    top: calc(70px + 20px);
    margin: 0 17px 0 20px;
    transition: all 0.5s ease 0s;
    font-family: "Gotham Pro Regular";
    font-size: 14px;
    color: $brandPantonGrey;
    overflow: auto;
}

.users {
    display: flex;
    flex-direction: column;
    padding: 27px 150px;
    width: 100%;

    .users__tab {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        border-bottom: 1px solid #EFEFEF;

        .tab__users {
            padding: 10px 20px;
            border-bottom: 1px solid transparent;

            &:hover {
                cursor: pointer;
            }
        }

        .tab__roles {
            padding: 10px 65px;
            border-bottom: 1px solid transparent;

            &:hover {
                cursor: pointer;
            }
        }

        .choosing__tabs {
            color: $brandPantonBlue;
            border-bottom: 1px solid $brandPantonBlue;
        }
    }

    .users__main {
        display: flex;
        flex-direction: column;
        width: 100%;
        border: 1px solid green;
    }
}