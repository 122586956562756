@import "../../../../styles/base.scss";

/**
 * ********** Стили компонента Прайс-лист **********
 */
.payment {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    // background: $white;

    .payment__heading {
        display: flex;
        flex-direction: column;
        position: relative;
    
        & > *:nth-child(n) {
            margin-bottom: 10px;
        }

        .payment__modal {
            display: none; // it turn off
            flex-direction: column;
            position: absolute;
            top: 90px;
            right: 10px;
            width: 257px;
            height: 180px;
            border: 1px solid $brandPantonFonts;
            background-color: $white;
            z-index: 1000;

            .payment__block--left {
                display: flex;
                justify-content: flex-end;
            }

            .modal__block {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px;

                .block__text {
                    margin-left: 10px;
                }

                .block__button {
                    padding: 10px 30px;
                    background-color: $white;
                    border-radius: 40px;
                    color: $brandPantonBlue;
                    border: 1px solid $brandPantonBlue;

                    &:hover {
                        background-color: $brandPantonBlue;
                        color: $white;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .payment__table {
        overflow-y: scroll;

        .payment__frame {
            display: flex;
            flex-direction: column;

            .frame__caption {
                display: flex;
                flex-direction: column;
                position: sticky;
                top: 0;
                width: 180px;//min-width: 150px;
                margin: 4px;
                background-color: $white;

                .wrap__index {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;
                    text-align: center;
                    height: 40px;
                    padding: 5px;
                    background: $white;
                    border: 1px solid $brandBorderColor;
                    // border-top: 1px solid $brandPantonFonts;
                    // border-bottom: 1px solid $brandPantonFonts;
                    cursor: pointer;
                    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1); 

                    .index__text {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        color: $brandPantonBlue;
                    }

                    &--active {
                        color: $white!important;
                        background: $brandPantonBlue;

                        .index__text {
                            color: $white;
                        }

                        /* стили для изменения иконки при фильтрации */
                        .index__icon--sorting {
                            display: block;
                            margin-left: 10px;
                            width: 23px;
                            height: 14px;
                            background: url('../../../../images/sorting_column_white.svg')!important;
                        }

                        /* стили для изменения иконки при фильтрации */
                        .index__icon--filtering {
                            display: block;
                            width: 16px;
                            height: 14px;
                            margin-left: 10px;
                            background: url('../../../../images/filter_white.svg')!important;
                        }
                    }

                    .index__icon--sorting {
                        display: block;
                        margin-left: 10px;
                        width: 20px;
                        height: 14px;
                        background: url('../../../../images/sorting_column.svg') no-repeat center;
                    }

                    .index__icon--filtering {
                        display: block;
                        width: 15px;
                        height: 15px;
                        margin-left: 10px;
                        background: url('../../../../images/filter.svg') no-repeat center;
                    }

                    &:hover {
                        border: 1px solid $brandPantonBlue;
                    }
                   
                }
                .wrap__search {
                    display: flex;

                    .search__range {
                        padding: 0 5px;
                    }
                }
            }

            .frame__table {
                display: flex;
                flex-direction: row;
                justify-content: center;
                min-height: 50vh;

                .table__column {
                    // flex: 1;
                    // width: 100%;
                    display: flex;
                    flex-direction: column;

                    // &:nth-child(1) {
                    //     flex: 2;
                    // }

                    // &:nth-child(2) {
                    //     flex: 1;
                    // }

                    &:nth-child(2),
                    &:nth-child(3) {
                        // flex: 1;
                        .column__item {
                            justify-content: flex-end;
                        }
                    }

                    &:nth-child(4) {
                        // flex: 1;
                        .column__item {
                            justify-content: flex-end;
                        }
                    }

                    &:nth-child(5) {
                        // flex: 2;
                    }

                    // &:last-child {
                    //     border-right: 1px solid $brandBorderColor;
                    // }

                    .column__item {
                        display: flex;
                        align-items: center;
                        height: 30px;
                        padding: 5px;
                        width: 180px;// max-width: 425px;
                        min-width: 150px;
                        margin: 4px;
                        border: 1px solid $brandBorderColor;
                        background-color: $white;
                        // border-bottom: 1px solid $brandBorderColor;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        cursor: default;

                        span {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 50ch;
                        }
    
                        // &:nth-child(odd) {
                        //     background-color: #f1f1f1;
                        // }
    
                        .item__text {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            
                        }
                        .item__icon {
                            display: block;
                            width: 16px;
                            height: 16px;
                            margin-right: 5px;
                            background: url('../../../../images/plus_item.svg') no-repeat center;
                            background-size: contain;

                            &:hover {
                                cursor: pointer;
                            }
                        }

                        &:hover {
                            border: 1px solid $brandPantonBlue;
                        }
                    }
                }
            }
        }
    }
}

.button {
    display: block;
    padding: 13px 20px;
    color: $brandPantonBlue;
    border: 1px solid $brandPantonBlue;
    border-radius: 40px;
    font-family: $font-regular;
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);

    &:hover {
        background-color: $brandPantonBlue;
        color: $white;
        cursor: pointer;
    }
}

.react-datetimerange-picker {
    display: flex;
    // position: inherit;

    &>span {
        display: block!important;
        position: absolute;
        bottom: -12px;
    }
}

.react-datetimerange-picker--open {
    position: none!important;
}

.react-datetimerange-picker__inputGroup {
    display: flex;
    align-items: center;
    min-width: 0;

    input:hover {
        transition: none;
    }
}

.react-datetimerange-picker__wrapper {
    border: none;
}

.react-datetimerange-picker__button svg {
    stroke-width: 1;
}

.react-datetimerange-picker__calendar-button {
    display: none;
    border: 0;
    background: transparent;
}
