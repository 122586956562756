@import "../../../../styles/base.scss";

.main-content {
    height: calc(100vh - 70px - 20px - 20px);
    flex: 2 100%;
    flex-direction: column;
    position: sticky;
    top: calc(70px + 20px);
    margin: 0 17px 0 20px;
    transition: all 0.5s ease 0s;
    font-family: "Gotham Pro Regular";
    font-size: 14px;
    color: $brandPantonGrey;
    overflow: auto;
}
// .width {
//     height: calc(100vh - 70px)!important;
//     top: 70px;
//     margin: 0 1px 0 2px!important;
//     overflow-x: hidden;
//     background: $white;
// }

/**
 * ********** Стили компонента Профиль **********
 */
.profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 927px;
    padding: 0 17px 0 0px;
    // background-color: #ffffff;

    .profile__header {
        width: 99%;
        // margin-top: 31px;
        margin-left: 37px;
        font-size: 20px;
        line-height: 40px;
        font-family: $font-regular;
    }

    .profile__main {
        display: flex;
        flex-direction: column;
        width: 927px;
        background-color: $white;

        .main__user {
            display: flex;
            position: relative;
            flex-direction: row;
            align-items: center;
            // padding-bottom: 35px;
            margin: 37px 37px 0 37px;

            .user__photo {
                display: flex;
                position: relative;
                width: 159px;
                height: 159px;
                margin-right: 30px;
                border-radius: 50%;
                background: url('../../../../images/profile_avatar.svg') no-repeat center;
                background-color: $brandBackground;

                .photo__pencil {
                    display: flex;
                    position: absolute;
                    right: 0;
                    bottom: 20px;
                    width: 31px;
                    height: 31px;
                    border-radius: 50%;
                    background: url('../../../../images/change_avatar_pensil.svg') $brandPantonBlue no-repeat center;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .photo__change {
                display: flex;
                position: absolute;
                left: 150px;
                bottom: -50px;
                width: 150px;
                background-color: $white;
                border-radius: 8px;
                box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
                z-index: 1000;

                .change__list {
                    width: 100%;
                }

                .change__list > li:nth-child(3) {
                    color: $brandPantonRed;
                }

                .change__list > li {
                    width: 100%;
                    font-size: 12px;
                    padding: 7px 10px;

                    &:hover {
                        background-color: $brandBackground;
                    }
                }
            }

            .user__info {
                display: flex;
                flex-direction: column;

                .info__status {
                    margin-bottom: 10px;
                    font-size: 14px;
                    color: $brandPantonSubText;
                }

                .info__name {
                    margin-bottom: 20px;
                    font-size: 16px;
                    color: $brandPantonGrey;
                }

                .info__data {
                    font-size: 14px;
                    color: $brandPantonSubText;
                }
            }
        }

        .main__nav {
            display: flex;
            margin: 30px 50px;
            border-bottom: 1px solid $brandBackground;

            .nav__data {
                padding: 10px 20px;
                font-size: 16px;
                border-bottom: 1px solid transparent;

                &:hover {
                    cursor: pointer;
                }
            }

            .nav__password {
                padding: 10px 20px;
                font-size: 16px;
                border-bottom: 1px solid transparent;

                &:hover {
                    cursor: pointer;
                }
            }

            .choosing__tabs {
                color: $brandPantonBlue;
                border-bottom: 1px solid $brandPantonBlue;
            }
        }
    }
}