/**
 * ********** Подключение шрифтов **********
 */
@font-face {
	font-family: "Gotham Pro Light";
	src: url("./GothamProLight/GothamProLight.eot");
	src: url("./GothamProLight/GothamProLight.eot")format("embedded-opentype"),
		url("./GothamProLight/GothamProLight.woff") format("woff"),
		url("./GothamProLight/GothamProLight.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "Gotham Pro Regular";
	src: url("./GothamProMonospace-Regular/GothamProMonospace-Regular.ttf"); //.eot
	// src: url("./GothamProMonospace-Regular/GothamProMonospace-Regular.eot"),
	// url("./GothamProMonospace-Regular/GothamProMonospace-Regular.woff");
	//	url("./GothamProMonospace-Regular/GothamProMonospace-Regular.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "Gotham Pro";
	src: url("./GothamProMedium/GothamProMedium.eot");
	src: url("./GothamProMedium/GothamProMedium.eot")format("embedded-opentype"),
		url("./GothamProMedium/GothamProMedium.woff") format("woff"),
		url("./GothamProMedium/GothamProMedium.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}