@import "../../../../styles/base.scss";

.right-info__profile {
    display: flex;
    position: relative;

    .right-info__list {
        &:hover {
            cursor: pointer;
        }
    }

    .info-list__arrow {
        display: block;
        width: 30px;
        height: 30px;
        transition: all 0.15s cubic-bezier(0.02, 0.01, 0.47, 1);
        background: url('../../../../images/arrow_drop.svg') no-repeat center;
        background-size: contain;

        &:hover {
            cursor: pointer;
        }
    }

    .profile {
        display: block;
        position: relative;
        height: 46px;
        width: 46px;
        padding: 1.25rem;
        background: url('../../../../images/profile-photo.png') no-repeat;
        background-size: contain;
        border-radius: 50%;
        margin-left: 40px;

        &__status {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            padding: 7px;
            border-radius: 50%;
            background-color: $brandPantonBlue;
        }
    }

    .dropdown {
        width: calc(100% - 39px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 60px;
        left: 0;
        background-color: $white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        z-index: 10000;

        &__item {
            display: flex;
            align-items: center;
            width: 100%;
            // margin: 5px;
            font-size: 16px;
            color: $brandPantonText;
            border-left: 1px solid transparent;
            border-bottom: 1px solid #F1F1F1;

            &:hover {
                // color: $brandPantonBlue;
                border-left: 1px solid $brandPantonBlue;
                cursor: pointer;
            }

            .item__profile {
                display: block;
                width: 30px;
                height: 30px;
                margin: 10px;
                background: url('../../../../images/profile-user.svg') no-repeat center;
            }

            .item__settings {
                display: block;
                width: 30px;
                height: 30px;
                margin: 20px;
                background: url('../../../../images/settings-user.svg') no-repeat center;
            }
        }

        &__logout {
            width: 196px;
            height: 30px;        
            padding: 5px 25px;
            margin: 20px 0;
            border-radius: 20px;
            text-align: center;
            color: $brandPantonBlue;
            border: 1px solid $brandPantonBlue;

            &:hover {
                background-color: $brandPantonBlue;
                color: $white;
                transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
                cursor: pointer;
            }
        }
    }
}

.toggle-window {
    transform: rotate(-180deg);
}