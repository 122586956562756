@import '../../../../styles/base.scss';
@import '../../../../styles/reset.scss';

/* стили для развернтуг окна */

.chat {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 450px;
    height: 500px;
    margin-right: 40px;
    margin-bottom: 40px;
    padding: 20px;
    background-color: $brandBackground;
    border: 1px solid #C4C4C4;
    z-index: 100000;

    .chat__header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-content: space-between;
        flex: 1;

        .header__left {
            display: flex;
            flex: 2;
            margin-top: 7px;

            .left__photo {
                width: 50px;
                height: 50px;
                margin-right: 20px;
                background: url('../../../../images/profile-photo.png') no-repeat center;
                background-size: contain;
                border-radius: 50%;
            }

            .left__text {
                display: flex;
                flex-direction: column;

                .text__name {
                    font-size: 16px;
                    color: $brandPantonBlack;
                }

                .text__status {
                    font-size: 14px;
                    color: $brandPantonSubText;
                }

                .green {
                    color: $brandPantonGreen;
                }
            }
        }

        .header__right {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            flex: 1;
            height: 100%;

            .right__rollup {
                width: 30px;
                height: 20px;
                // margin-right: 20px;
                background: url('../../../../images/chat/close_chat.svg') no-repeat center;
                background-size: contain;

                &:hover {
                    cursor: pointer;
                }
            }

            .right__close {
                width: 20px;
                height: 20px;
                background: url('../../../../images/chat/close_chat.svg') no-repeat center;
                background-size: contain;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .chat__window {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        // justify-content: flex-end;
        flex: 5;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .chat__footer {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex: 1;
        // padding: 10px 0;
        border-radius: 60px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);

        .footer__add {
            display: flex;
            padding: 8px 15px;
            border-radius: 50%;
            color: $brandPantonSubText;
            font-size: 25px;
            background-color: #EFEFEF;
            // border: 1px solid red;
        }

        .footer__input {
            padding: 5px 0;
            border: 1px solid red;
        }

        ::placeholder {
            font-family: $font-regular;
            font-size: 14px;
            color: $brandBorderColor;
        }

        .footer__btn {
            display: flex;
            padding: 10px;
            background-color: $brandPantonBlue;
            border-radius: 50%;

            .button__image {
                display: block;
                width: 20px;
                height: 20px;
                background: url('../../../../images/chat/submit_message.svg') no-repeat center;
                background-size: contain;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}

/* стили для свернутого окна */
.chat__rollup {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70px;
    height: 70px;
    margin-right: 40px;
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 50%;
    background-color: $brandPantonBlue;
    animation-duration: 1s;
    animation-name: chat;
    animation-iteration-count: infinite;
    z-index: 100000;

    @keyframes chat {
        from {
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.8);
        }

        to {
            box-shadow: 0px 0px 0px rgb(0, 0, 0);
        }
    }

    &:hover {
        cursor: pointer;
    }

    .rollup__status {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0px;
        width: 20px;
        height: 20px;
        color: $white;
        background-color: $brandPantonGreen;
        border-radius: 50%;

        & > span {
            font-size: 13px;
        }
    }

    .rollup__image {
        width: 30px;
        height: 30px;
        background: url('../../../../images/chat/rollup_chat_icon.svg') no-repeat center;
        background-size: contain;
    }
}