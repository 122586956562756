@import '../../../../../styles/reset.scss';
@import '../../../../../styles/base.scss';


.main__role {
    display: flex;
    flex-direction: column;
    position: relative;

    .role__header {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-bottom: 30px;

        .header__input {
            display: flex;
            align-items: flex-end;
            flex: 1;

            .input {
                flex: 1;
                height: 35px;
                background: url('../../../../../images/search.svg') right no-repeat;
                border-bottom: 1px solid #EFEFEF
            }

            ::placeholder {
                font-family: $font-regular;
                font-size: 14px;
                color: $brandPantonSubText;
            }
        }

        .header__button {
            display: flex;
            justify-content: flex-end;
            flex: 1;

            .button {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 196px;
                // height: 30px;
                padding: 13px 20px;
                border-radius: 30px;
                border: 1px solid $brandPantonBlue;

                .button__text {
                    font-family: $font-regular;
                    font-size: 14px;
                    color: $brandPantonBlue;
                }

                .button__icon {
                    display: block;
                    width: 20px;
                    height: 20px;
                    margin-left: 20px;
                    background: url('../../../../../images/add_users_roles.svg') no-repeat center;
                    background-size: contain
                }

                &:hover {
                    color: $white;
                    cursor: pointer;

                    .button__text {
                        color: $white;
                    }
                }
            }
        }
    }

    .role__employments {
        display: flex;
        flex-direction: column;

        .employments__block {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);

            .block__left {
                display: flex;
                flex-direction: row;
                flex: 1;

                .left__photo {
                    width: 50px;
                    height: 50px;
                    margin: 15px 30px;
                    border-radius: 50%;
                    background: url('../../../../../images/profile-photo.png') no-repeat center;
                    background-size: contain;
                }

                .left__info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .info__name {
                        padding-bottom: 10px;
                        font-size: 14px;
                        color: $brandPantonBlack;
                    }

                    .info__email {
                        font-size: 12px;
                        color: $brandPantonSubText;
                    }
                }
            }

            .block__right {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .right__options {
                    display: flex;
                    align-items: center;
                    margin-right: 30px;
                
                    .change {
                        width: 25px;
                        height: 25px;
                        background: url('../../../../../images/usersRoles/change_users_or_role.svg') no-repeat center;
                        background-size: contain;

                        &:hover {
                            cursor: pointer;
                        }
                    }
    
                    .close {
                        margin-left: 20px;
                        width: 15px;
                        height: 15px;
                        background: url('../../../../../images/usersRoles/delete_users_roles.svg') no-repeat center;
                        background-size: contain;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}